import {Edit_USER} from  '../actions/actionTypes';
const initialState = {
  mode:"",
  userData:{}
}

const userReducer=(state=initialState,action)=>{
  if(action.type === Edit_USER){
    return action.payload

  }
  return state;
}
export default userReducer;
