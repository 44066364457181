
module.exports = {
    WRONGPASSWORD:"Wrong password",
    NO_USER_FOUND:"No User Found",
    INVALID_PASSWORD:"You have entered wrong password",
    INVALID_EMAIL:"You have entered wrong Email",
    SUCCESSFUL_LOGIN:"Login Successfull!",
    STATUS_CODE_200:200,
    STATUS_CODE_404:404,
    STATUS_CODE_400:400,
    STATUS_CODE_401:401,
    STATUS_CODE_500:500,
    manager:"manager",
    DATAEDITED:"Data updated successfully",
    DATAADDED:"Data added successfully",
    GAMEEDITEDMESSAGE:"Game Details updated successfully",
    TOURNAMENTEDITEDMESSAGE:"Tournament Details updated successfully",
    GAMEADDEDMESSAGE:"Game Details added successfully",
    TOURNAMENTADDEDMESSAGE:"Tournament Details added successfully",
    comingSoon:'This feature will be available soon',
    warning:'warning',
    Session:"Session",
    Session_Expired:" Your Session has been expired. Please Sign in",
    user:"user",
    samePassword:"Both passwords are sames",
    superAdmin:"superAdmin",
    ACTIVE:"Active",
    sameInputs:"No change in inputs",
    Game:"Game",
    Students:"Students",
    Teachers:"Teachers",
    SchoolAdmin:"School Administrator",
    ProgramAmbassadors:"Program Ambassador",
    ProgramDirector:"Program Director",
    Quizzes:"Quizzes",
    Tests:"Tests",
    Licenses:"Licenses",
    Lessons:"Lessons",
    Messages:"Messages",
    Report:"Report",
    Tournament:"Tournament",
    Scheduler:"Scheduler",
    DEACTIVE:"deActive",
    LOGINFAILED:"Login Unsuccessful",
    Error_MESSAGE:"error",
    success:"success",
    SUCCESSMESSAGE:"success",
    CreateUser:"CreateUser",
    CreateTag:"CreateTag",
    UpdateTag:"UpdateTag",
    EditUser:"EditUser",
    UPDATED_DATA:"Data has successfully updated",
    EditUserSuccess:"User has been Edited Successfully",
    User:"User",
    Login:"Login",
    ResetPassword:"Login",
    ForgetPassword:'Forget Password',
    successForget:"Successfully! Sent the verification Code to your Email",
    successReset:"Your password has been changed Sucessfully!",
    captchaError:"kindly apply captcha",
    Register:"Registration",
    successRegistration:"User has been Registered Successfully",
    captchaSuccess:"Captcha has been verified sucessfully",
    successFetch:"Successfully Fetched Users",
  Upcoming:"Upcoming",
  Completed:"Completed",
  Disabled:"Disabled",
  Scheduled:"Scheduled",


  RegisterRoleChoise: [
    {
        value: 'ProgramDirector',
        label: 'ProgramDirector'
    }
  ],
  RegisterRoleChoise22: [
    {
      value: 'Student',
      label: 'Student'
    }
  ],
  RoleChoise: [
      {
          value: 'Student',
          label: 'Student'
      },
      {
        value: 'Teacher',
        label: 'Teacher'
      },
      {
        value: 'ProgramAmbassador',
        label: 'ProgramAmbassador'
      },
      {
        value: 'SchoolAdmin',
        label: 'SchoolAdmin'
      },
      {
        value: 'ProgramDirector',
        label: 'ProgramDirector'
      },
       
  ],
  DISTRICTS: [
      {
      value: 'district-1',
      label: 'district-1',
      },
      {
        value: 'district-2',
        label: 'district-2',
      },
      {
        value: 'district-3',
        label: 'district-3',
      },
      {
        value: 'district-4',
        label: 'district-4',
      },
      {
        value: 'district-5',
        label: 'district-5',
      },
      {
        value: 'district-6',
        label: 'district-6',
      },
      {
        value: 'district-7',
        label: 'district-7',
      },
      {
        value: 'district-8',
        label: 'district-8',
      },
      {
        value: 'district-9',
        label: 'district-9',
      },
      {
        value: 'district-10',
        label: 'district-10',
      },
],
CLASSES: [
    {
    value: 'class-1',
    label: 'class-1',
    },
    {
      value: 'class-2',
      label: 'class-2',
    },
    {
      value: 'class-3',
      label: 'class-3',
    },
    {
      value: 'class-4',
      label: 'class-4',
    },
    {
      value: 'class-5',
      label: 'class-5',
    },
    {
      value: 'class-6',
      label: 'class-6',
    },
    {
      value: 'class-7',
      label: 'class-7',
    },
    {
      value: 'class-8',
      label: 'class-8',
    },
    {
      value: 'class-9',
      label: 'class-9',
    },
    {
      value: 'class-10',
      label: 'class-10',
    },
],
  LEVELTYPE: [
    {
        value: 0,
        label: "None"
    },
    {
        value: 7,
        label: "GrandMaster"
    },
    {
        value: 6,
        label: "Master"
    },
    {
        value: 5,
        label: "Diamond"
    },
    {
        value: 4,
        label: "Platinum"
    },
    {
        value: 3,
        label: "Gold"
    },
    {
        value: 2,
        label: "Silver"
    },
    {
        value: 1,
        label: "Bronze"
    }
],


SUBLEVELTYPE: [
    {
        value: 0,
        label: "None"
    },
    {
        value: 1,
        label: "Warrior"
    },
    {
        value: 2,
        label: "Hero"
    },
    {
        value: 3,
        label: "Legend"
    }
],

    Priority: [
        {
            value: "1",
            label: "1"
        },
        {
            value: "2",
            label: "2"
        },
        {
            value: "3",
            label: "3"
        },
        {
            value: "4",
            label: "4"
        },
        {
            value: "5",
            label: "5"
        }
    ],
    TOURNAMENT_STATUS: [
        {
            value: "Active",
            label: "Active"
        },
        {
            value: "Upcoming",
            label: "Upcoming"
        },
        {
            value: "Completed",
            label: "Completed"
        },
        {
            value: "Disabled",
            label: "Disabled"
        },
    ],
    TOURNAMENT_STATUS_ADD: [
        {
            value: "Upcoming",
            label: "Upcoming"
        }
    ],
    LEVELS: [
        {
            value: 1,
            label: 1
        },
        {
            value: 2,
            label: 2
        },
        {
            value: 3,
            label: 3
        },
        {
            value: 4,
            label: 4
        },
        {
            value: 5,
            label: 5
        },
    ],
    NO_OF_TEAMS: [
        {
            value: "2",
            label: "2"
        },
        {
            value: "4",
            label: "4"
        },
        {
            value: "8",
            label: "8"
        },
        {
            value: "16",
            label: "16"
        },
        {
            value: "32",
            label: "32"
        }
    ],
    NO_OF_PLAYERS: [
        {
            value: "2",
            label: "2"
        },
        {
            value: "4",
            label: "4"
        },
        {
            value: "8",
            label: "8"
        },
        {
            value: "16",
            label: "16"
        },
        {
            value: "32",
            label: "32"
        }
      
        
    ],
    NO_OF_TEAMPLAYERS: [
        {
            value: "1",
            label: "1"
        },
        {
            value: "2",
            label: "2"
        },
        {
            value: "4",
            label: "4"
        },
        {
            value: "8",
            label: "8"
        },
        {
            value: "16",
            label: "16"
        },
        {
            value: "32",
            label: "32"
        }
      
        
    ],
    Color: [
        {
            value: "Red",
            label: "Red"
        },
        {
            value: "Green",
            label: "Green"
        },
        {
            value: "Yellow",
            label: "Yellow"
        },
        {
            value: "Oranage",
            label: "Oranage"
        },
        {
            value: "Maroon",
            label: "Maroon"
        }
    ],
    NO_OF_PLAYERSPERMATCH: [
        {
            value: 2,
            label: 2
        },
        {
            value: 4,
            label: 4
        },
        {
            value: 6,
            label: 6
        },
        {
            value: 8,
            label: 8
        },
        {
            value: 10,
            label: 10
        }
      
        
    ],



}
