import axios from "axios";
import { URL } from "../config/config";

//getUsers
export const getUsers = (data) => {
  return axios.post(URL + `/getUsers`, data, {
    headers: {
      Authorization: localStorage.getItem("accessId"),
    },
  });
};

//deleteUser
export const deleteUser = (data) => {
  return axios.post(URL + `/deleteUser`, data, {
    headers: {
      Authorization: localStorage.getItem("accessId"),
    },
  });
};

//getupdateUserUsers
export const updateUser = (data) => {
  return axios.put(URL + `/updateUser`, data, {
    headers: {
      Authorization: localStorage.getItem("accessId"),
    },
  });
};

//getUser
export const getUser = (data) => {
  return axios.post(URL + `/getUser`, data, {
    headers: {
      Authorization: localStorage.getItem("accessId"),
    },
  });
};

//registerUser
export const registerUser = (data) => {
  return axios.post(URL + `/registerUser`, data);
};

export const sendBulkEmails = (data) => {
  return axios.post(URL + "/sendBulkMail", data, {
    headers: {
      Authorization: localStorage.getItem("accessId"),
    },
  });
};

/////Add Notifications

//getNotifications
export const getNotifications = (data) => {
  return axios.post(URL + `/getNotifications`, data, {
    headers: {
      Authorization: localStorage.getItem("accessId"),
    },
  });
};

//deleteNotification
export const deleteNotification = (data) => {
  return axios.post(URL + `/deleteNotification`, data, {
    headers: {
      Authorization: localStorage.getItem("accessId"),
    },
  });
};

//updateNotification
export const updateNotification = (data) => {
  return axios.put(URL + `/updateNotification`, data, {
    headers: {
      Authorization: localStorage.getItem("accessId"),
    },
  });
};

//getNotification
export const getNotification = (data) => {
  return axios.post(URL + `/getNotification`, data, {
    headers: {
      Authorization: localStorage.getItem("accessId"),
    },
  });
};

//getLatestNotifications
export const getLatestNotifications = (data) => {
  return axios.post(URL + `/getLatestNotifications`, data, {
    headers: {
      Authorization: localStorage.getItem("accessId"),
    },
  });
};
//updateScore
export const updateScore = (data) => {
  return axios.post(URL + `/updateScore`, data, {
    headers: {
      Authorization: localStorage.getItem("accessId"),
    },
  });
};

//getLatestQuizTests
export const getLatestQuizTests = (data) => {
  return axios.post(URL + `/getLatestQuizTests`, data, {
    headers: {
      Authorization: localStorage.getItem("accessId"),
    },
  });
};

//addNotification
export const addNotification = (data) => {
  return axios.post(URL + `/addNotification`, data);
};

export const forgetPass = (data) => {
  return axios.post(URL + `/forgetPass`, data);
};
export const resetPassword = (data) => {
  return axios.post(URL + `/resetPassword`, data);
};

//editTag
export const editUser = (data) => {
  return axios.put(URL + `/editUser`, data, {
    // headers: {
    //     accessToken: localStorage.getItem('accessId')
    // }
  });
};
export const disableUser = (data) => {
  return axios.put(URL + `/disableUser`, data, {
    // headers: {
    //     accessToken: localStorage.getItem('accessId')
    // }
  });
};

export const contactUs = (data) => {
  return axios.post(URL + `/contactUs`, data);
};
export const LoginUser = (data) => {
  return axios.post(URL + `/loginUser`, data);
};
export const getAllUsers = (data) => {
  console.log(
    "--------------------------------- id is ",
    localStorage.getItem("accessId")
  );
  return axios.get(URL + `/getAllUsers`, {
    // headers: {
    //     accessToken: localStorage.getItem('accessId'),
    //     "Access-Control-Allow-Headers":localStorage.getItem('accessId')
    // }
  });
};
export const getUserProfile = (username) => {
  console.log(
    "--------------------------------- id is ",
    localStorage.getItem("accessId")
  );
  return axios.get(URL + `/getUserProfile/` + username);
};
export const changePassword = (data) => {
  data.accessToken = localStorage.getItem("accessId");
  console.log("--------------------------------- data is ", data);
  return axios.post(URL + `/changePassword`, data);
};
export const getCurrentSession = (data) => {
  return axios.get(URL + `/currentsession`, data);
};
