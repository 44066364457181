// Base URLS

//BASE_URL local
//  export const URL = "http://localhost:3000/dev";
// export const URL_2 = "http://localhost:3000/dev";

//stripeeee
//live
// //LIVEURLS
export const URL = "https://ia3nqkp6th.execute-api.us-east-2.amazonaws.com/dev";
export const URL_2 =
  "https://ia3nqkp6th.execute-api.us-east-2.amazonaws.com/dev";

// //LIVEURLS  // test
// export const URL = "https://4nzsr52uv6.execute-api.us-east-2.amazonaws.com/dev";
// export const URL_2 = "https://4nzsr52uv6.execute-api.us-east-2.amazonaws.com/dev";
