import {ForgetPass} from  '../actions/actionTypes';
const initialState = {
  userEmail:""
}

const forgetPassReducer=(state=initialState,action)=>{
  if(action.type === ForgetPass){
    return action.payload

  }
  return state;
}
export default forgetPassReducer;
