import {AddTour} from  '../actions/actionTypes';
import {EditTour} from  '../actions/actionTypes';
const initialState = {
  mode:"",
  itemId:""
}

const tournamentReducer=(state=initialState,action)=>{
  if(action.type === EditTour){
    return action.payload
  }
  else if(action.type === AddTour){
    return action.payload
  }
  return state;
}
export default tournamentReducer;
