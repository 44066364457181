import React, { Component } from "react";
import { HashRouter, Route, Switch, BrowserRouter } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import "../src/scss/Landing/css/style.css";
import Dashboard from "./components/Dashboard/Dashboard";
import { Provider } from "react-redux";
import { store, persistor } from "../src/store";
import { PersistGate } from "redux-persist/integration/react";
import "antd/dist/antd.css";
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./components/Accounts/Login"));
const GamePage = React.lazy(() =>
  import("./components/Accounts/Login/GamePage")
);
const ForgetPassword = React.lazy(() =>
  import("./components/Accounts/ForgetPassword")
);
const ResetPassword = React.lazy(() =>
  import("./components/Accounts/ResetPassword")
);
const Register = React.lazy(() => import("./components/Accounts/Register"));
const Page404 = React.lazy(() => import("./components/Accounts/Page404"));
const Page500 = React.lazy(() => import("./components/Accounts/Page500"));
const Landing = React.lazy(() => import("./components/Landing/landing"));
const ContactUs = React.lazy(() => import("./components/Accounts/ContactUs"));
const EightRounds = React.lazy(() =>
  import("./components/Tournament/treeView/eightRounds")
);
const EightRoundsResult = React.lazy(() =>
  import("./components/Tournament/treeView/eightRoundsResult")
);

const Help = React.lazy(() => import("./components/Accounts/Help"));

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route
                  exact
                  path="/"
                  name="Landing Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/game"
                  name="Game Page"
                  render={(props) => <GamePage {...props} />}
                />
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/forgetPassword"
                  name="forget Password"
                  render={(props) => <ForgetPassword {...props} />}
                />
                <Route
                  exact
                  path="/contactus"
                  name="Contact Us"
                  render={(props) => <ContactUs {...props} />}
                />
                <Route
                  exact
                  path="/resetPassword"
                  name="Reset Password"
                  render={(props) => <ResetPassword {...props} />}
                />
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  render={(props) => <Register {...props} />}
                />
                <Route
                  exact
                  path="/help"
                  name="Help Page"
                  render={(props) => <Help {...props} />}
                />
                <Route
                  exact
                  path="/tournamentschedule"
                  name="Tournament Tree"
                  render={(props) => <EightRounds {...props} />}
                />
                <Route
                  exact
                  path="/tournamentscheduleResult"
                  name="Tournament Schedule"
                  render={(props) => <EightRoundsResult {...props} />}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={(props) => <Page500 {...props} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <DefaultLayout {...props} />}
                />
              </Switch>
            </React.Suspense>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
