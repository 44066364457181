import { combineReducers } from 'redux'
import loginReducer from "./loginReducer";
import forgetPassReducer from "./forgetPassword";
import gameReducer from "./gameReducer";
import  userReducer from "./userReducer";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import UserIdReducer from "./UserIdReducer";
import tournamentReducer from "./tournamentReducer";
const persistConfig={
  key:'root',
  storage,
  whitelist:['LoginReducer','forgetReducer','gameReducer','UserReducer',"UserIdReducer","tourReducer"]
};
const rootReducer = combineReducers({
  LoginReducer:loginReducer,
  forgetReducer:forgetPassReducer,
  gameReducer:gameReducer,
  UserReducer:userReducer,
  UserIdReducer:UserIdReducer,
  tourReducer:tournamentReducer

});


export default persistReducer(persistConfig,rootReducer);
