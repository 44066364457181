import { notification } from "antd";
import {
    RadiusUpleftOutlined,
    RadiusUprightOutlined,
    RadiusBottomleftOutlined,
    RadiusBottomrightOutlined,
  } from '@ant-design/icons';

export const  openNotification= (type, title, content) => {
    console.log("notification is " , type , title, content);
        notification[type]({
            message: title,
            description:content,
            placement:'bottomRight',
        });
    }
